import { createWebHistory, createRouter } from 'vue-router'
import type {
    RouteRecord,
    RouteRecordRaw,
    RouterScrollBehavior,
} from 'vue-router'
import { layout, redirect, moduleRoute } from '../helper/routes'
import Toast from '@src/libs/toast'
import useUserStore from '@src/stores/user'

/** Interceptor error 403 and 401 axios */
import axios from '@src/libs/axios'
import type { Route } from '../models/Route'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { name: 'def', path: '/', component: { template: '<div>Home</div>' } },
    ],
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash)
            return {
                selector: to.hash,
            }
        if (savedPosition) return savedPosition

        return {
            x: 0,
            y: 0,
        }
    } as RouterScrollBehavior,
})

export function resetRouter() {
    router.getRoutes().forEach((route) => {
        if (route.name) router.removeRoute(route.name)
    })
}
function getTypeUser() {
    let type
    const userStore = useUserStore()
    if (userStore.isLoggedIn) {
        if (userStore.isAdmin) {
            type = 'admin'
        } else {
            type = 'user'
        }
    } else {
        type = 'guest'
    }
    return type
}

function getRouterForArray(items: Route[]) {
    let routes: RouteRecordRaw[] = []

    items.forEach(function (element) {
        if (element.routes) {
            routes = routes.concat(getRouterForArray(element.routes))
        } else if (element.location) {
            let module
            if (element.location.includes('@')) {
                let aux = element.location.split('@')
                module = moduleRoute(aux[0], aux[1], element.path)
            } else {
                module = moduleRoute('Core', element.location, element.path)
            }
            if (module) routes.push(module)
        }
    })
    return routes
}

async function updateRouter() {
    const userStore = useUserStore()
    let type = getTypeUser()
    resetRouter()
    let itemsNavigation: Route[] = []
    let routes: RouteRecordRaw[] = [],
        route: RouteRecordRaw | null

    switch (type) {
        case 'guest':
            route = moduleRoute('Core', 'AdminLogin', '/login/admin')
            route && routes.push(route)

            route = moduleRoute('Core', 'UserLogin', '/login/user/:redirectTo?')
            route && routes.push(route)

            route = moduleRoute('Core', 'Register', '/register/:redirectTo?')
            route && routes.push(route)

            router.addRoute(layout('Login', 'Login', routes, '/login'))
            router.addRoute(
                redirect(
                    '/:catchAll(.*)', // Unrecognized path automatically matches 404,
                    () => {
                        return '/login/user'
                    },
                    'Redirect User'
                )
            )
            //router.addRoute(error(404, "/:catchAll(.*)"))
            break
        case 'user':
        case 'admin':
            itemsNavigation = userStore.routeItems
            if (itemsNavigation.length === 0) {
                // quando esta vazio de items ele pega um default
                itemsNavigation = [
                    {
                        name: 'NoAccess',
                        alias: 'NA',
                        title: 'Core.routes.NoAccess',
                        location: 'Core@NoAccess',
                        icon: 'mdi-alert-circle',
                        path: '/',
                    },
                ]
            }
            routes = getRouterForArray(itemsNavigation)
            router.addRoute(layout('Default', 'AdminTemplate', routes, '/'))

            // Select system route
            router.addRoute(
                layout(
                    'Login',
                    'Login',
                    [moduleRoute('Core', 'SelectSystem', '/system/select')],
                    '/system'
                )
            )
            router.addRoute(
                redirect(
                    '/:catchAll(.*)', // Unrecognized path automatically matches 404,7
                    () => {
                        return '/'
                    },
                    'Redirect Home'
                )
            )
            //router.addRoute(error(404, '/:catchAll(.*)'))
            userStore.routeItems = itemsNavigation
            break
    }

    // router.push({
    //     path: router.currentRoute.value.fullPath,
    //     query: { root: 'true' },
    // })

    router.addRoute(
        layout(
            'Login',
            'Invitation',
            [
                moduleRoute(
                    'Core',
                    'EmployeeInvitation',
                    '/core/web/employeeInvitation/:token'
                ),
                moduleRoute('Core', 'AutoLogin', '/core/autologin/user/:token'),
            ],
            '/core'
        )
    )
    router.addRoute(
        layout(
            'Login',
            'StaticForms',
            [moduleRoute('GI', 'StaticEneagrama', '/gi/candidateForm/:token')],
            '/gi'
        )
    )
}

let isRefreshing = false

const backLogin = (err: any) => {
    updateRouter()
    if (useUserStore().isAdmin) {
        router.push('/login/admin/')
        return Promise.reject(err)
    } else {
        router.push('/login/user/')
        return Promise.reject(err)
    }
}

axios.interceptors.response.use(
    (response) => {
        return response
    },
    async (err: any) => {
        const {
            config,
            response: { status },
        } = err
        const userStore = useUserStore()

        const originalRequest = config
        if (originalRequest.url.includes('/auth/refresh')) {
            if (window.location.href.includes('/login/')) {
                return Promise.reject(err)
            } else {
                backLogin(err)
            }
        }

        if (originalRequest.url.includes('/auth/login')) {
            return Promise.reject(err)
        }

        if (status === 403) {
            return Promise.reject(err)
        } else if (status === 401 && userStore.isLoggedIn) {
            if (!isRefreshing) {
                isRefreshing = true
                await userStore
                    .refreshToken()
                    .then((response) => {
                        if (
                            response.status === 200 ||
                            response.status === 204
                        ) {
                            isRefreshing = false
                            if (import.meta.env.DEV)
                                Toast({
                                    icon: 'info',
                                    title: 'Token foi renovado',
                                })
                        }
                    })
                    .catch((error) => {
                        userStore.isLoggedIn = false
                        userStore.isAdmin = false
                        if (import.meta.env.DEV)
                            Toast({
                                icon: 'warning',
                                title: 'Falha na tentativa de renovar o token',
                            })
                        backLogin(error)
                    })
                if (!isRefreshing) {
                    return axios(originalRequest)
                }
            } else {
                if (
                    originalRequest.url.includes('/auth/refresh') ||
                    originalRequest.url.includes('logout')
                ) {
                    return Promise.reject(err)
                } else
                    return new Promise((resolve) =>
                        setTimeout(resolve, 5000)
                    ).then(() => axios(originalRequest))
            }
        } else {
            return Promise.reject(err)
        }
    }
)

window.router = router
window.updateRouter = updateRouter
export default router
