import { views } from '@src/modules'
import useAppStore from '@src/stores/app'
import type { RouteLocation, RouteRecordName, RouteRecordRaw } from 'vue-router'

function leadingSlash(str: string) {
    return str.startsWith('/') ? str : '/' + str
}

export function error(code = 404, path = '*') {
    return {
        name: 'Error ' + code,
        path: path,
        component: () => import('@src/vue/errors/404.vue'),
    }
}

const layouts: { [key: string]: () => Promise<unknown> } = {}
const pathLayouts = import.meta.glob('@src/vue/layouts/*/Index.vue')
for (const path in pathLayouts) {
    const splitPath = path.split('/')
    const layout = splitPath[5]
    layouts[layout.toLowerCase()] = pathLayouts[path]
}

export function layout(
    layout = 'default',
    name: string,
    children: RouteRecordRaw[],
    path: string
): RouteRecordRaw {
    return {
        name: name,
        children,
        component: lazyLoaderHandler(layouts[layout.toLowerCase()]),
        path,
    }
}

export function redirect(
    path = '*',
    rhandler: (to: RouteLocation) => string,
    name?: string
): RouteRecordRaw {
    if (typeof path === 'function') {
        rhandler = path
        path = '*'
    }

    return {
        name: name || 'redirect_' + path,
        path,
        redirect: (to: RouteLocation) => {
            const rpath = rhandler(to)
            const url = rpath !== '' ? leadingSlash(rpath) : rpath

            return `${url}`
        },
    }
}

function lazyLoaderHandler(fn: () => Promise<any>) {
    return () => {
        // Loading begins now
        const appStore = useAppStore()
        appStore.lazyLoadingRoute = true

        // Call when loading is done
        const done = () => {
            appStore.lazyLoadingRoute = false
        }

        const promise = fn()
        promise.then(done, done)
        return promise
    }
}

export function moduleRoute(
    moduleName: string,
    viewName: string,
    path = ''
): RouteRecordRaw {
    let route = null
    if (!(moduleName in views)) {
        return {
            name: moduleName + '@' + viewName,
            path: path,
            component: () => import('@src/vue/errors/404.vue'),
        }
    }
    let item = views[moduleName].find(({ name }: { name: string }) => {
        return viewName === name
    })

    if (item != null) {
        route = {
            name: moduleName + '@' + viewName,
            component: lazyLoaderHandler(item.import),
            path,
        }
    } else {
        route = {
            name: moduleName + '@' + viewName,
            path: path,
            component: () => import('@src/vue/errors/404.vue'),
        }
    }

    return route
}
